<script>
import VueApexCharts from 'vue-apexcharts'

const m = {
  ...VueApexCharts,
  beforeDestroy: function beforeDestroy() {
  },
  destroyed: function destroyed() {
    setTimeout(() => {
      if (!this.chart) {
        return
      }

      this.destroy()
    }, 1000)
  },
}

export default {
  mixins: [m],
}
</script>
