<template>
  <b-card
    v-touch:start="onStart"
    v-touch:tap="onTap"
    v-touch:longtap="onLongTap"
    v-touch:moving="onEnd"
    v-touch:end="onEnd"
    class="touch-card"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <div
      v-if="holdOn"
      class="hold"
    />
    <slot />
  </b-card>
</template>

<script>
import {
  BCard,
} from 'bootstrap-vue'

export default {
  components: {
    BCard,
  },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    disable: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      holdOn: false,
    }
  },
  methods: {
    onStart(e) {
      if (this.disabled || this.disable) return
      this.holdOn = true
      this.$emit('actionStart', e)
    },
    async onTap(e) {
      if (this.disabled || this.disable) return

      this.holdOn = true
      this.$emit('action', e)
      await window.sleep(200)
      this.holdOn = false
    },
    async onLongTap(e) {
      if (this.disabled || this.disable) return

      this.holdOn = true
      this.$emit('actionLongTap', e)
      await window.sleep(200)
      this.holdOn = false
    },
    onEnd(e) {
      this.$nextTick(async () => {
        await window.sleep(200)
        this.holdOn = false
        this.$emit('actionEnd', e)
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.touch-card {
  overflow: hidden;

  .hold {
    background-color: rgba(0, 0, 0, 0.2) !important;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
}
</style>
